<template>
  <el-drawer
      title="详细信息"
      v-model="drawer"
      :with-header="false">
    <div v-for="url in urls" >
      <el-image :src="url"  style="transform: scale(1) rotate(0deg); margin-left: 0px; margin-top: 0px; max-height: 100%; max-width: 100%;" lazy></el-image>
    </div>
    <div v-for="video in videos" >
      <video  style="transform: scale(1) rotate(0deg); margin-left: 0px; margin-top: 0px; max-height: 100%; max-width: 100%;"
          v-bind:src="video"
          class="video-avatar"
          :autoplay="true"
          controls="controls"
          muted
          loop
      ></video>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "actorInfo",
  data() {
    return {
      drawer: false,
      urls:[],
      videos:[]
    }
  },
  methods:{
    showInfo(info) {
      this.drawer = true
      this.urls =[]
      this.videos =[]
      if (info.urls != undefined || info.urls != null) {
        if (info.urls.length > 0) {
          info.urls.forEach((url) => {
            this.urls.push(url.url)
          })
        }
      }
      if (info.videos != undefined || info.videos != null) {
        if (info.videos.length > 0) {
          info.videos.forEach( (video) => {
            this.videos.push(video.url)
          })
        }
      }

    }
  }
}
</script>

<style scoped>

</style>