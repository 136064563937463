<template>
  <div class="home" >
    <Actor/>
  </div>
</template>

<script>
// @ is an alias to /src
import Actor from '@/components/Actor.vue'

export default {
  name: 'HomeView',
  components: {
    Actor
  }
}
</script>
