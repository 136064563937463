import axios from 'axios'
import { ElMessage,ElLoading  } from 'element-plus'
import {ref} from "vue";
import QS from "qs";  //这个是引入的Element 3.0的提示消息
const loading = ref(false) // loading
const openLoading = () => {
    loading.value = ElLoading.service({
        lock: true,
        text: '客官稍等~正在拼命加载中!!',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}
const closeLoading = () => {
    loading.value.close()
}

// 举例

const service = axios.create({
    baseURL: 'https://new-api.bondtechin.com/', // 自己项目中请求地址前缀部分
    // baseURL: 'http://127.0.0.1:48080/', // 自己项目中请求地址前缀部分
    timeout: 180000, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json',
        'token': 'your token',
        'X-Requested-With': 'XMLHttpRequest',
    },
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        openLoading()
        // 在发送请求之前做些什么
        return config
    },
    function (error) {
        // 对请求错误做些什么
        closeLoading()
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        closeLoading()
        // 这个地方响应了数据该做点什么 做想做的事情
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        // 这个状态码是和后端约定的
        // const code = dataAxios.reset
        return dataAxios
    },
    function (error) {
        closeLoading()
        // 对响应错误做点什么
        ElMessage({
            message: '接口报错了',
            type: 'success',
        })
        return Promise.reject(error)
    }
)

// export function get(url, params){
//     return new Promise((resolve, reject) =>{
//         axios.get(url, {
//             params: params
//         }).then(res => {
//             resolve(res.data);
//         }).catch(err =>{
//             reject(err.data)
//         })
//     });
// }
// export function post(url, params) {
//     return new Promise((resolve, reject) => {
//         axios.post(url, QS.stringify(params))
//             .then(res => {
//                 resolve(res.data);
//             })
//             .catch(err =>{
//                 reject(err.data)
//             })
//     });
// }
export default service   //最后暴露出去
