<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="text-center">演员信息</h1>
      </div>
    </div>
    <el-tabs  v-model="queryParams.actorStatus" type="border-card" @tab-click="handleClick">
      <el-tab-pane  v-for="dict in actorStatus" :key="dict.value" :label="dict.label" :value="dict.value" :name="dict.value" >
        <div  style="text-align:left;margin-bottom: 20px">
          <div >
            <span>演员类型: </span>
            <el-radio-group v-model="queryParams.actorTitle"  style="margin-bottom: 10px;" @change="changeActorTitle">
              <el-radio-button  v-for="dict in actorTitle" :label="dict.value" :value="dict.value" >{{dict.label}}</el-radio-button>
            </el-radio-group>
          </div>
          <div>
            <span >形象标签: </span>
            <el-radio-group  v-model="queryParams.charactersTitle" style="margin-bottom: 10px;" @change="changeCharactersTitle">
              <el-radio-button  v-for="dict in charactersTitle" :label="dict.value" :value="dict.value" >{{dict.label}}</el-radio-button>
            </el-radio-group>
          </div>
          <div  >
            <span >预约状态: </span>
            <el-radio-group  v-model="queryParams.bookingStatus" style="margin-bottom: 10px;" @change="changeBookingStatus">
              <el-radio-button  v-for="dict in bookingStatus" :label="dict.value" :value="dict.value" >{{dict.label}}</el-radio-button>
            </el-radio-group>
          </div>
          <div  >
            <span >演员姓名: </span>
            <el-input
                placeholder="请输入演员姓名"
                v-model="queryParams.name"
                style="width: 15%"
                @change="changeName"
                clearable>
            </el-input>
          </div>
          <div style="margin-top: 10px">
            <span >演员艺名: </span>
            <el-input
                placeholder="请输入演员艺名"
                v-model="queryParams.stageName"
                style="width: 15%"
                @change="changeStageName"
                clearable>
            </el-input>
          </div>
        </div>


        <el-skeleton style="width:400px" :loading="loading" animated :count="3">

        </el-skeleton>
            <el-row :gutter="20" style="width:100%">
              <el-col :span="6" v-for="actor in actors" style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04) ;border-radius: 2px" >
                <div >
                  <el-card :body-style="{ padding: '0px' }">
                    <el-image :src="actor.url"  style="transform: scale(1) rotate(0deg); margin-left: 0px; margin-top: 0px; max-height: 100%; max-width: 100%;" lazy >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div style="padding: 14px;">
                      <p style="">
                        <span>姓名： </span> <span>{{actor.name}}</span><br/>
                        <span>艺名： </span> <span>{{actor.stageName}}</span><br/>
                        <span>演员状态： </span> <span>{{getDictDatas(actorStatus,actor.actorStatus)}}</span><br/>
                        <span>演员类型： </span> <span>{{getDictDatas(actorTitle,actor.actorTitle)}}</span><br/>
                        <span>形象标签： </span> <span>{{getDictDatas(charactersTitle,actor.charactersTitle)}}</span><br/>
                        <span>预约状态： </span> <span>{{getDictDatas(bookingStatus,actor.bookingStatus)}}</span><br/>
                        <span>备注： </span> <span>{{actor.remake}}</span><br/>
                      </p>
                      <div class="bottom clearfix">
                        <el-button type="text" class="button"  @click="showActorInfo(actor)">详细信息</el-button>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
      </el-tab-pane>
    </el-tabs>

  </div>

    <actor-info ref="actorInfo" ></actor-info>


</template>

<script>
import httpRequest from "@/request/request"
import actorInfo from '@/components/actorInfo.vue'
export default {
  name: 'Actor',
  components: { actorInfo },
  data() {
    return {
      loading: true,
      drawer: false,
      queryParams :{
        actorTitle: null,
        charactersTitle: null,
        actorStatus: '1',
        bookingStatus: null,
        name: null,
      },
      actors:[],
      actorTitle:[],
      charactersTitle:[],
      actorStatus:[],
      bookingStatus:[],
    };
  },
  created() {
    this.initDict ()
    this.getData ()
  },
  computed: {

  },
  methods: {
    getData () {
      this.actors = []
      httpRequest.get("admin-api/actor/info/open/list",{
        params: this.queryParams
      }).then(req => {
        req.data.forEach(data=>{
          let actor = {};
          actor.name = data.name
          actor.stageName = data.stageName
          actor.actorStatus = data.actorStatus
          actor.actorTitle = data.actorTitle
          actor.charactersTitle = data.charactersTitle
          actor.bookingStatus = data.bookingStatus
          actor.remake =  data.remake
          if (data.urls != null && data.urls.length > 0) {
            actor.url = data.urls[0].url
            actor.urls = data.urls
          }
          if (data.videos != null && data.videos.length > 0) {
            actor.video = data.videos[0].url
            actor.videos = data.videos
          }
          this.actors.push(actor)
        })
        this.loading =false
      });
    },
    handleClick(tab) {
      this.loading = true
      this.queryParams.actorStatus = tab.paneName
      this.queryParams.actorTitle =""
      this.queryParams.charactersTitle =""
      this.queryParams.name =""
      this.queryParams.bookingStatus =""
      this.getData()
    },
    // 手动改变radio值
    changeActorTitle(value){
      this.loading = true
      this.queryParams.actorTitle = value
      this.getData()
    },
    changeCharactersTitle(value){
      this.loading = true
      this.queryParams.charactersTitle = value
      this.getData()
    },
    changeBookingStatus(value){
      this.loading = true
      this.queryParams.bookingStatus = value
      this.getData()
    },
    changeName(value){
      this.loading = true
      this.queryParams.name = value
      this.getData()
    },
    changeStageName(value){
      this.loading = true
      this.queryParams.stageName = value
      this.getData()
    },
    initDict() {
      httpRequest.get("/admin-api/system/dict-data/list-simple?dictType=actor_status").then(request =>{
        this.actorStatus = request.data
      })
      httpRequest.get("/admin-api/system/dict-data/list-simple?dictType=actor_personality").then(request =>{
        this.charactersTitle = request.data
      })
      httpRequest.get("/admin-api/system/dict-data/list-simple?dictType=actor_type").then(request =>{
        this.actorTitle = request.data
      })
      httpRequest.get("/admin-api/system/dict-data/list-simple?dictType=booking_status").then(request =>{
        this.bookingStatus = request.data
      })
    },
    getDictDatas(dictValue, values) {
      if (values === undefined || values === null) {
        return "";
      }
      // 如果是单个元素，则转换成数组
      if (!Array.isArray(values)) {
        values = values.split(',')
      }
      // 获得字典数据
      const results = [];
      for (const value of values) {
        const dict = dictValue;
        if (dict) {
          results.push(dict);
        }
      }
      let label =  '';
        results[0].forEach(element => {
            if (values.indexOf(element.value) != -1) {
              if (label=== ''){
                label += element.label
              }else {
                label += ","+element.label
              }
            }
        })
      return label;
    },
    showActorInfo(info) {
      this.drawer =true
      this.$refs['actorInfo'].showInfo(info)

    },
    }
};
</script>
<style scoped>
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.row {
  max-width: 100%;
  margin-bottom: 20px;
}

.col-lg-12 {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}
</style>